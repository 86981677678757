<template>
  <div v-if="dataLoaded" style="margin: 10px; width: calc(100vw - 85px)">
    <el-dialog :close-on-click-modal="false" :visible.sync="isOpenMaintenanceForm" :title="isNewMaintenance?$t('settings.maintenance_add'):$t('settings.maintenance_edit')" top="2vh">
      <el-form ref="maintenance" :model="maintenanceForm" :rules="rules">
        <el-form-item prop="name" :label="$t('settings.maintenance_name')">
          <el-input v-model="maintenanceForm.name" />
        </el-form-item>
        <el-form-item>
          <el-radio-group v-model="maintenanceForm.type">
            <el-radio label="totalDistance">{{ $t('settings.maintenance_type_totalDistance') }}</el-radio>
            <el-radio label="period">{{ $t('settings.maintenance_type_period') }}</el-radio>
            <el-radio label="hours">{{ $t('settings.maintenance_type_hours') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-row>
          <el-col v-if="maintenanceForm.type === 'totalDistance' || maintenanceForm.type === 'hours'" :span="10">
            <el-form-item class="" :label="$t('settings.maintenance_start') + ' ('+(maintenanceForm.type === 'totalDistance' ? 'Km' : $t('settings.maintenance_hours'))+')'">
              <el-input-number v-model="maintenanceForm.start" :min="0" :precision="0" />
            </el-form-item>
          </el-col>
          <el-col v-if="maintenanceForm.type === 'period'" :span="10">
            <el-form-item class="" :label="$t('settings.maintenance_start_date')">
              <el-date-picker
                v-model="maintenanceForm.startDate"
                type="date"
                :placeholder="$t('settings.startDate')"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item class="" :label="$t('settings.maintenance_period') + ' (' + (maintenanceForm.type === 'period' ? $t('settings.maintenance_months') : (maintenanceForm.type === 'totalDistance' ? 'Km' : $t('settings.maintenance_hours'))) + ')'">
              <el-input-number v-model="maintenanceForm.period" :min="0" :precision="0" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-tabs stretch>
          <el-tab-pane>
            <span slot="label">
              <i class="fas fa-car"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="selectedDevices"
                :filter-method="filteredDevices"
                filterable
                :filter-placeholder="$t('settings.search')"
                :titles="[$t('settings.vehicles'), $t('settings.transfer_selected')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="devices"
                :render-content="renderFunc"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <i class="fas fa-grip-horizontal"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="selectedGroups"
                filterable
                :filter-placeholder="$t('report.selector_search')"
                :titles="[$t('settings.groups'), $t('report.select_groups')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="groups"
                :render-content="renderFunc"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <div slot="footer">
        <el-button
          type="info"
          class="formButton"
          size="small"
          @click="handleCancelForm"
        >{{ $t('settings.form_cancel') }}</el-button>
        <el-button
          :loading="loading"
          type="success"
          class="formButton"
          size="small"
          @click="handleSubmitForm"
        >{{ $t('settings.form_save') }}</el-button>
      </div>
    </el-dialog>
    <div style="float: left; padding-bottom: 20px">
      <el-tooltip :content="$t('settings.add')" placement="top">
        <el-switch
          v-model="showDevicesMaintenance"
          :active-text="$t('settings.by_vehicle')"
          :inactive-text="$t('settings.by_maintenance')"
          @change="calculateDevicesMaintenances"
        >
        </el-switch>
      </el-tooltip>
    </div>
    <div style="padding-bottom:5px; padding-right:10px; float: right">
      <el-button :loading="downloadLoading" type="primary" @click="handleDownloadPDF">
        <i class="fa-solid fa-file-pdf"></i>
        PDF
      </el-button>
    </div>
    <div style="padding-bottom:5px; padding-right:10px; float: right">
      <el-button :loading="downloadLoading" type="primary" @click="handleDownload">
        <i class="fa-solid fa-file-excel"></i>
        Excel
      </el-button>
    </div>
    <el-table
      v-if="showDevicesMaintenance"
      :data="filteredDevicesMaintenances"
      height="calc(100vh - 140px)"
      :row-style="tableRowStyle"
      :header-cell-style="tableHeaderStyle"
    >
      <el-table-column key="d_1" :label="$t('settings.vehicle')" prop="name" sortable />
      <el-table-column key="d_2" prop="maintenance" :label="$t('settings.maintenance')" sortable />
      <el-table-column key="d_3" prop="currentKms" :label="$t('settings.vehicle_kms')" align="right" />
      <el-table-column key="d_4" prop="currentHours" :label="$t('settings.maintenance_hours')" align="right" sortable />
      <el-table-column key="d_5" prop="nextMaintenance" :label="$t('settings.next_maintenance')" align="right" sortable>
        <template slot-scope="device">
          {{ device.row.type === 'totalDistance' || device.row.type === 'hours'
            ? device.row.nextMaintenance
            : device.row.nextMaintenance.toLocaleDateString() }}
        </template>
      </el-table-column>
      <el-table-column key="d_6" prop="kms" :label="$t('settings.remaining_kms')" align="right" sortable />
      <el-table-column key="d_7" prop="hours" :label="$t('settings.remaining_hours')" align="right" sortable />
    </el-table>
    <el-table
      v-if="!showDevicesMaintenance"
      height="calc(100vh - 140px)"
      :data="filteredMaintenances"
      :row-style="tableRowStyle"
      :header-cell-style="tableHeaderStyle"
      @row-dblclick="handleDoubleClick"
      @expand-change="calculateMaintenance"
    >
      <div v-if="!maintenancesProcessed" v-loading="true"></div>
      <el-table-column
        :min-width="20"
        type="expand"
      >
        <template slot-scope="scope">
          <el-table
            v-if="!showDevicesMaintenance"
            :data="scope.row.devicesMaintenances"
            :row-style="tableRowStyle"
            :header-cell-style="tableHeaderStyle"
            cell-class-name="cellInfo"
          >
            <el-table-column key="m_1" prop="name" :label="$t('settings.vehicle')" width="200" sortable />
            <el-table-column v-if="scope.row.type === 'totalDistance' || scope.row.type === 'time'" key="m_2" prop="currentKms" :label="$t('settings.vehicle_kms')" align="right" width="175" sortable />
            <el-table-column v-if="scope.row.type === 'hours'" key="m_3" prop="currentHours" :label="$t('settings.maintenance_hours')" align="right" width="175" sortable />
            <el-table-column key="m_4" prop="nextMaintenance" :label="$t('settings.next_maintenance')" align="right" width="175">
              <template slot-scope="maintenance">
                {{ maintenance.row.type === 'totalDistance' || maintenance.row.type === 'hours' ? maintenance.row.nextMaintenance : maintenance.row.nextMaintenance.toLocaleDateString() }}
              </template>
            </el-table-column>
            <el-table-column v-if="scope.row.type === 'totalDistance'" key="m_5" prop="kms" :label="$t('settings.remaining_kms')" align="right" width="175" sortable />
            <el-table-column v-if="scope.row.type === 'hours'" key="m_6" prop="hours" :label="$t('settings.remaining_hours')" align="right" width="175" sortable />
          </el-table>
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="$t('settings.maintenance_name')" sortable show-overflow-tooltip />
      <el-table-column prop="type" :label="$t('settings.maintenance_type')" sortable show-overflow-tooltip>
        <template slot-scope="scope">
          {{ $t('settings.maintenance_type_'+scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('settings.maintenance_start')" align="right" prop="start" width="200" sortable>
        <template slot-scope="scope">
          {{
            scope.row.type === "totalDistance" ? Math.round(scope.row.start / 1000) + ' Kms'
            : (scope.row.type === "hours" ? Math.round(scope.row.start / (60 * 60 * 1000)) + ' H'
              : new Date(scope.row.start).toLocaleDateString())
          }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('settings.maintenance_period')" align="right" prop="period" width="200" sortable>
        <template slot-scope="scope">
          {{ scope.row.type === "totalDistance" ? Math.round(scope.row.period / 1000) + ' Kms'
            : (scope.row.type === "hours" ? Math.round(scope.row.period / (60 * 60 * 1000)) + ' H'
              : scope.row.period + ' ' + $t('settings.maintenance_months'))
          }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('settings.vehicles')" align="right" width="175">
        <template slot-scope="scope">
          {{ scope.row.devices ? scope.row.devices.length : 0 }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('settings.groups')" align="right" width="175">
        <template slot-scope="scope">
          {{ scope.row.groups ? scope.row.groups.length : 0 }}
        </template>
      </el-table-column>
      <el-table-column align="right" label="" :min-width="150">
        <template slot="header">
          <div style="float: right">
            <el-tooltip :content="$t('settings.add')" placement="top">
              <el-button
                class="tableButton"
                size="small"
                @click="handleAddMaintenance"
              ><i class="fas fa-plus"></i></el-button>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <el-tooltip :content="$t('settings.delete')" placement="top">
            <el-button
              v-if="!isMobile"
              class="tableButton"
              size="small"
              type="danger"
              @click="handleDeleteMaintenance(scope.row)"
            ><i class="fas fa-trash-alt"></i></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('settings.edit')" placement="top">
            <el-button
              v-if="!isMobile"
              size="small"
              class="tableButton"
              @click="handleEdit(scope.row)"
            ><i class="fas fa-edit"></i></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="!showDevicesMaintenance"
      layout="total"
      :total="maintenances.length"
    >
    </el-pagination>
    <el-pagination
      v-if="showDevicesMaintenance"
      layout="total"
      :total="devicesMaintenances.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { traccar } from '@/api/traccar-api'
import { vm } from '@/main'
import * as lnglat from '../../../utils/lnglat'
import { getUserPartner } from 'fleetmap-partners'
import { header } from 'fleetmap-reports/src/util/pdfDocument'
import { getStyle } from 'fleetmap-reports/src/reportStyle'
const jsPDF = require('jspdf')
require('jspdf-autotable')

export default {
  name: 'Maintenance',
  data() {
    return {
      loading: false,
      showDevicesMaintenance: false,
      isOpenMaintenanceForm: false,
      maintenancesProcessed: false,
      isNewMaintenance: false,
      selectedMaintenance: null,
      downloadLoading: false,
      selectedDevices: [],
      selectedGroups: [],
      maintenanceForm: {
        name: '',
        start: 0,
        startDate: new Date(),
        period: 0,
        type: 'totalDistance'
      },
      rules: {
        name: [
          { required: true, message: this.$t('settings.name_required'), trigger: 'blur' }
        ]
      },
      devicesMaintenances: []
    }
  },
  computed: {
    ...mapGetters(['dataLoaded', 'devices', 'groups', 'maintenances', 'user', 'search']),
    filteredMaintenances() {
      return this.maintenances.filter(data => !this.search ||
        data.name.toLowerCase().includes(this.search.toLowerCase())).sort((a, b) => a.name > b.name ? 1 : -1)
    },
    filteredDevicesMaintenances() {
      return this.devicesMaintenances.filter(data => !this.search ||
        data.name.toLowerCase().includes(this.search.toLowerCase()) ||
        data.maintenance.toLowerCase().includes(this.search.toLowerCase())).sort((a, b) => a.name > b.name ? 1 : -1)
    },
    isMobile() { return lnglat.isMobile() }
  },
  mounted() {
    this.processMaitenances()
  },
  methods: {
    async processMaitenances() {
      if (this.maintenances.length) {
        await this.$store.dispatch('user/processMaitenances')
        this.maintenancesProcessed = true
      } else {
        setTimeout(this.processMaitenances, 1000)
      }
    },
    filteredDevices(query, item) {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 || (item.license_plate && item.license_plate.toLowerCase().indexOf(query.toLowerCase()) > -1)
    },
    tableRowStyle() {
      return 'font-size: 14px'
    },
    tableHeaderStyle() {
      return 'font-size: 14px'
    },
    handleCancelForm() {
      this.isOpenMaintenanceForm = false
      this.clearFormData()
    },
    async handleSubmitForm() {
      this.$refs.maintenance.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.isNewMaintenance) {
            this.submitNewMaintenance()
          } else {
            try {
              this.selectedMaintenance.name = this.maintenanceForm.name
              this.selectedMaintenance.type = this.maintenanceForm.type
              this.selectedMaintenance.start = this.maintenanceForm.type === 'totalDistance'
                ? this.maintenanceForm.start * 1000
                : (this.maintenanceForm.type === 'hours' ? this.maintenanceForm.start * 60 * 60 * 1000 : this.maintenanceForm.startDate.getTime())
              this.selectedMaintenance.period = this.maintenanceForm.type === 'totalDistance'
                ? this.maintenanceForm.period * 1000
                : (this.maintenanceForm.type === 'hours' ? this.maintenanceForm.period * 60 * 60 * 1000 : this.maintenanceForm.period)

              traccar.editMaintenance(this.selectedMaintenance.id, { ...this.selectedMaintenance })
                .then(() => { this.updated() })
            } catch (reason) {
              Vue.$log.error(reason)
              this.$alert(reason)
            } finally {
              this.loading = false
            }
          }
        } else {
          return false
        }
      })
    },
    submitNewMaintenance() {
      const newMaintenance = {
        name: this.maintenanceForm.name,
        attributes: { userId: this.user.id },
        type: this.maintenanceForm.type,
        start: this.maintenanceForm.type === 'totalDistance'
          ? this.maintenanceForm.start * 1000
          : (this.maintenanceForm.type === 'hours' ? this.maintenanceForm.start * 60 * 60 * 1000 : this.maintenanceForm.startDate.getTime()),
        period: this.maintenanceForm.type === 'totalDistance'
          ? this.maintenanceForm.period * 1000
          : (this.maintenanceForm.type === 'hours' ? this.maintenanceForm.period * 60 * 60 * 1000 : this.maintenanceForm.period)
      }
      traccar.addMaintenance(newMaintenance)
        .then(response => this.maintenanceCreated(response.data))
        .catch(reason => {
          Vue.$log.error(reason)
          this.$alert(reason)
          this.loading = false
        })
    },
    handleDownloadPDF() {
      const info = this.getHeaderAndData()
      const partner = getUserPartner(this.user)

      // eslint-disable-next-line new-cap
      const doc = new jsPDF.jsPDF('l')
      const style = getStyle(partner)
      header(doc, this.$t('settings.maintenance'), partner.host, style, 'l')
      doc.setFontSize(10)
      doc.text(new Date().toLocaleString(), 15, 20)
      doc.autoTable({
        head: [info.tHeader],
        body: info.data,
        foot: [[]],
        showFoot: 'lastPage',
        headStyles: {
          fillColor: style.pdfHeaderColor,
          textColor: style.pdfHeaderTextColor,
          fontSize: 10
        },
        bodyStyles: {
          fillColor: style.pdfBodyColor,
          textColor: style.pdfBodyTextColor,
          fontSize: 8
        },
        footStyles: {
          fillColor: style.pdfFooterColor,
          textColor: style.pdfFooterTextColor,
          fontSize: 9
        },
        startY: 30
      })

      doc.save('maintenance.pdf')
      this.downloadLoading = false
    },
    handleDownload() {
      const info = this.getHeaderAndData()

      import('../../../utils/ExportExcel').then(excel => {
        excel.export_json_to_excel({
          header: info.tHeader,
          data: info.data,
          filename: 'maintenance',
          autoWidth: false,
          bookType: 'xlsx'
        })
        this.downloadLoading = false
      })
    },
    getHeaderAndData() {
      this.downloadLoading = true
      this.calculateDevicesMaintenances()

      const tHeader = [
        this.$t('settings.vehicle'),
        this.$t('settings.maintenance'),
        this.$t('settings.vehicle_kms'),
        this.$t('settings.maintenance_hours'),
        this.$t('settings.next_maintenance'),
        this.$t('settings.remaining_kms'),
        this.$t('settings.remaining_hours')
      ]

      const data = this.filteredDevicesMaintenances.map(v => [
        v.name,
        v.maintenance,
        v.currentKms,
        v.currentHours,
        v.type === 'totalDistance' || v.type === 'hours' ? v.nextMaintenance : v.nextMaintenance.toLocaleDateString(),
        v.kms,
        v.hours
      ]
      )

      return { tHeader, data }
    },
    async updateMaintenancePermissions() {
      const devicesToRemove = this.selectedMaintenance.devices
        ? this.selectedMaintenance.devices.filter(x => !this.selectedDevices.includes(x))
        : []

      const devicesToAdd = this.selectedDevices.filter(x => this.selectedMaintenance.devices
        ? !this.selectedMaintenance.devices.includes(x) : true)

      const devicesPermissionsToRemove = devicesToRemove.map(dId => {
        return {
          deviceId: dId,
          maintenanceId: this.selectedMaintenance.id
        }
      })

      const devicesPermissionsToAdd = devicesToAdd.map(gId => {
        return {
          deviceId: gId,
          maintenanceId: this.selectedMaintenance.id
        }
      })

      await traccar.deleteAllPermissions(devicesPermissionsToRemove)
      await traccar.addAllPermissions(devicesPermissionsToAdd)

      const groupsToRemove = this.selectedMaintenance.groups
        ? this.selectedMaintenance.groups.filter(x => !this.selectedGroups.includes(x))
        : []

      const groupsToAdd = this.selectedGroups.filter(x => this.selectedMaintenance.groups
        ? !this.selectedMaintenance.groups.includes(x) : true)

      const groupsPermissionsToRemove = groupsToRemove.map(dId => {
        return {
          groupId: dId,
          maintenanceId: this.selectedMaintenance.id
        }
      })

      const groupsPermissionsToAdd = groupsToAdd.map(gId => {
        return {
          groupId: gId,
          maintenanceId: this.selectedMaintenance.id
        }
      })

      await traccar.deleteAllPermissions(groupsPermissionsToRemove)
      await traccar.addAllPermissions(groupsPermissionsToAdd)
    },
    maintenanceCreated(newMaintenance) {
      const permissionsToAdd = this.selectedDevices.map(dId => {
        return {
          deviceId: dId,
          maintenanceId: newMaintenance.id
        }
      })

      permissionsToAdd.push(...this.selectedGroups.map(gId => {
        return {
          groupId: gId,
          maintenanceId: newMaintenance.id
        }
      }))

      Vue.$log.debug(permissionsToAdd)

      traccar.addAllPermissions(permissionsToAdd)
        .then(() => {
          newMaintenance.devices = this.selectedDevices
          newMaintenance.groups = this.selectedGroups

          this.$message({
            type: 'success',
            message: this.$t('settings.maintenance_created')
          })
          this.isOpenMaintenanceForm = false
          this.clearFormData()
          this.$store.dispatch('user/addMaintenance', newMaintenance)
        })
        .catch(reason => {
          Vue.$log.error(reason)
          this.$alert(reason)
        })
        .finally(() => {
          this.loading = false
        })
    },
    updated: function() {
      this.maintenancesProcessed = false
      this.updateMaintenancePermissions()
        .then(() => {
          this.selectedMaintenance.devices = this.selectedDevices
          this.selectedMaintenance.groups = this.selectedGroups

          this.$message({
            type: 'success',
            message: this.$t('settings.maintenance_updated')
          })
          this.$store.commit('user/SET_MAINTENANCE', this.selectedMaintenance)
        })
        .catch(reason => {
          Vue.$log.error(reason)
          this.$alert(reason)
        })
        .finally(() => {
          this.isOpenMaintenanceForm = false
          this.clearFormData()
          this.loading = false
          this.maintenancesProcessed = true
        })
    },
    clearFormData() {
      this.maintenanceForm.name = ''
      this.maintenanceForm.type = 'totalDistance'
      this.maintenanceForm.start = 0
      this.maintenanceForm.startDate = new Date()
      this.maintenanceForm.period = 0
      this.selectedDevices = []
      this.selectedGroups = []
    },
    handleAddMaintenance() {
      this.isNewMaintenance = true
      this.isOpenMaintenanceForm = !this.isOpenMaintenanceForm
    },
    calculateDevicesMaintenances() {
      for (const maintenance of this.maintenances) {
        this.calculateMaintenance(maintenance)
      }
      this.devicesMaintenances = this.maintenances.map(m => m.devicesMaintenances).flat()
      this.devicesMaintenances.sort((a, b) => a.name > b.name ? 1 : -1)
    },
    calculateMaintenance(maintenance) {
      maintenance.devicesMaintenances = []

      if (!maintenance.devices) {
        return
      }

      for (const dId of maintenance.devices) {
        const device = this.devices.find(d => d.id === dId)

        if (device.position) {
          const ignoreOdometer = device.attributes['report.ignoreOdometer']
          const kms = Math.round((!ignoreOdometer ? (device.position.attributes.odometer || device.position.attributes.totalDistance) : device.position.attributes.totalDistance) / 1000)
          const hours = Math.round(device.position.attributes.hours / (60 * 60 * 1000)) || device.position.attributes.hours

          const deviceMaintenance = {
            type: maintenance.type,
            maintenance: maintenance.name,
            name: device.name,
            currentKms: kms,
            currentHours: hours
          }

          if (!maintenance.period) { continue }

          if (maintenance.type === 'totalDistance') {
            const start = Math.round(maintenance.start / 1000)
            const period = Math.round(maintenance.period / 1000)
            const nextMaintenance = kms < start ? start : ((kms - ((kms - start) % period)) + period)
            const kmsToMaintenance = nextMaintenance - kms

            deviceMaintenance.nextMaintenance = nextMaintenance
            deviceMaintenance.kms = kmsToMaintenance
          } else if (maintenance.type === 'hours') {
            const start = Math.round(maintenance.start / (60 * 60 * 1000))
            const period = Math.round(maintenance.period / (60 * 60 * 1000))
            const nextMaintenance = hours ? hours < start ? start : ((hours - ((hours - start) % period)) + period) : ''
            const hoursToMaintenance = hours ? nextMaintenance - hours : ''

            deviceMaintenance.nextMaintenance = nextMaintenance
            deviceMaintenance.hours = hoursToMaintenance
          } else {
            deviceMaintenance.nextMaintenance = this.getNextMaintenanceDate(maintenance)
          }

          maintenance.devicesMaintenances.push(deviceMaintenance)
          maintenance.devicesMaintenances.sort((a, b) => a.name > b.name ? 1 : -1)
        }
      }
    },
    getNextMaintenanceDate(maintenance) {
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)

      const nextMaintenanceDate = new Date(maintenance.start)
      nextMaintenanceDate.setHours(0, 0, 0, 0)

      while (nextMaintenanceDate.getTime() < currentDate.getTime()) {
        nextMaintenanceDate.setMonth(nextMaintenanceDate.getMonth() + maintenance.period)
      }

      return nextMaintenanceDate
    },
    handleEdit(row) {
      this.isNewMaintenance = false
      this.selectedMaintenance = row
      this.maintenanceForm.name = row.name
      this.maintenanceForm.type = row.type
      this.maintenanceForm.start = row.type === 'totalDistance' ? Math.round(row.start / 1000) : (row.type === 'hours' ? Math.round(row.start / (60 * 60 * 1000)) : '0')
      this.maintenanceForm.startDate = row.type === 'period' ? new Date(row.start) : new Date()
      this.maintenanceForm.period = row.type === 'totalDistance' ? Math.round(row.period / 1000) : (row.type === 'hours' ? Math.round(row.period / (60 * 60 * 1000)) : row.period)
      this.selectedDevices = row.devices ? row.devices : []
      this.selectedGroups = row.groups ? row.groups : []
      this.isOpenMaintenanceForm = !this.isOpenMaintenanceForm
    },
    handleDeleteMaintenance(row) {
      this.$confirm(this.$t('settings.maintenance_delete_info') + row.name, this.$t('settings.maintenance_delete_title'), {
        confirmButtonText: this.$t('settings.form_confirm'),
        cancelButtonText: this.$t('settings.form_cancel')
      }).then(() => {
        traccar.deleteMaintenance(row.id)
          .then(() => this.maintenanceDeleted(row.id))
          .catch(reason => {
            Vue.$log.debug(reason)
            if (reason.response.data.startsWith('Account is readonly')) {
              this.$message({
                message: this.$t('settings.maintenance_delete_not_allowed'),
                type: 'warning',
                duration: 5 * 1000
              })
            } else {
              Vue.$log.error(reason)
              this.$alert(reason)
            }
          })
      }).catch(() => {
      })
    },
    maintenanceDeleted(id) {
      this.$log.debug('maintenance deleted')
      this.$message({
        message: this.$t('settings.maintenance_deleted'),
        type: 'success',
        duration: 5 * 1000
      })
      const maintenanceDeleted = vm.$store.state.user.maintenances.find(g => g.id === id)
      this.$store.dispatch('user/removeMaintenance', maintenanceDeleted)
    },
    renderFunc(h, option) {
      return <span title={option.name}>{option.name}</span>
    },
    handleDoubleClick(row) {
      this.handleEdit(row)
    }
  }
}
</script>
